import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squats 4×6\\@70% 1RM with a :02 pause at the bottom of each rep`}</p>
    <p>{`Glute Ham Raise 4×6 `}<em parentName="p">{`(or Single Leg Bent Leg Bridge 4×6/leg)`}</em></p>
    <p>{`then,`}</p>
    <p>{`200M Run`}</p>
    <p>{`10/Leg Alternating Barbell Front Rack Stationary Lunges (95/65)(RX+
115/75)`}</p>
    <p>{`400M Run`}</p>
    <p>{`12/Leg Alternating Barbell Front Rack Stationary Lunges`}</p>
    <p>{`800M Run`}</p>
    <p>{`14/Leg Alternating Barbell Front Rack Stationary Lunges`}</p>
    <p>{`for time.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`This Saturday we’ll be having a free class at 9:00 & 10:15am!  To
sign up please email us at: Daniel\\@crossfittheville.org`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      